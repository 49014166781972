$(function(){ if (document.getElementById("home_index") || document.getElementById("remote_jobs_index")) {
  const subscribeForm = $("#subscribe_remote_job_form");
  const subscribeSuccessMessage = $('#subscribe_success');
  const subscribeErrorMessage = $('#subscribe_error');
  const subscribeButton = $('#subscribe_button');
  const subscribeInputEmailArea = $('#input_email_area');

  subscribeForm.on("ajax:before", function(event, xhr, settings) {
    subscribeSuccessMessage.addClass('is-hidden');
    subscribeErrorMessage.addClass('is-hidden');

    subscribeSuccessMessage[0].innerHTML = '';
    subscribeErrorMessage[0].innerHTML = '';
  });

  subscribeForm.on("ajax:success", function(event) {
    const responseJson = JSON.parse(event.detail[2]['response']);

    subscribeSuccessMessage[0].innerHTML = responseJson['message'];
    subscribeSuccessMessage.removeClass('is-hidden');

    subscribeButton.addClass('is-hidden');
    subscribeInputEmailArea.addClass('is-hidden');

    writeSubscribeHiddenCookie(7)
  });

  subscribeForm.on("ajax:error", function(event) {
    let message;

    if(event.detail[0]['message'] !== undefined) {
      message = event.detail[0]['message'].join('<br/>');
    } else {
      message = 'Error! Happening!';
    }

    subscribeErrorMessage[0].innerHTML = message;
    subscribeErrorMessage.removeClass('is-hidden');
  });

  const subscribeContent = $('#subscribe_content');
  $(document).on("click", "#subscribe_form_close_button", function(e){
    subscribeContent.addClass('is-hidden');
    writeSubscribeHiddenCookie(7)
  });

  function writeSubscribeHiddenCookie(day) {
    const expiredDay = day;
    const expires = new Date();
    expires.setDate(expires.getDate() + expiredDay);
    document.cookie = 'hidden_subscribe=true;expires=' + expires;
  }

  const cookies = getCookieArray();
  const hidden_subscribe = cookies["hidden_subscribe"];
  if (hidden_subscribe === undefined) {
    $('#subscribe_content').removeClass("is-hidden");
  }

  function getCookieArray(){
    const arr = new Array();
    if(document.cookie != ''){
      const tmp = document.cookie.split('; ');
      for(let i=0;i<tmp.length;i++){
        const data = tmp[i].split('=');
        arr[data[0]] = decodeURIComponent(data[1]);
      }
    }
    return arr;
  }
}});