import { apply_access_counter } from "./apply_access_counter";

$(function(){ if ( document.getElementById("remote_jobs_show")) {
  const copiedArea = document.getElementById('copy_field');
  copiedArea.addEventListener('click', copyJob);

  let selectedTextColor;

  function copyJob() {
    document.getElementById('copy_job').select();
    document.execCommand("copy");

    const colors = ['red', 'orange', 'blue', 'hotpink', 'darkgreen']

    const index = colors.indexOf(selectedTextColor);
    colors.splice(index, 1)

    const color = colors[Math.floor(Math.random() * colors.length)]

    document.getElementById('copied_text').style.color = color;
    document.getElementById('copied').classList.remove('is-hidden');

    selectedTextColor = color;
  }

  apply_access_counter();

  $(document).on('click', '.click_event_apply_link', function () {
    const showReferralModel = () => {
      $('#referral_modal').addClass("is-active");
    }

    if(this.dataset.isReferral === "true") {
      showReferralModel();
    }
  });

  $(document).on('click', '#do_close_referral_modal, .modal-background', function () {
    const closeReferralModel = () => {
      $('#referral_modal').removeClass("is-active");
    }
    closeReferralModel();
  });


  document.addEventListener('keydown', (event) => {
    const e = event || window.event;

    if (e.keyCode === 27) { // Escape key
      $('#referral_modal').removeClass("is-active");
    }
  });
}});