$(function(){ if ( document.getElementById("remind_extend_payment_intents_show")) {
  // A reference to Stripe.js initialized with a fake API key.
  // Sign in to see examples pre-filled with your key.
  const publicApiKey = window.location.host == 'remoteful.dev' ? 'pk_live_mM7izdstV465VDFo98vQtM6y00czrMoKr2' : 'pk_test_phZDq6sigkezFVEO2XcNm9N900TFeV43rt'
  var stripe = Stripe(publicApiKey);

  // The items the customer wants to buy
  // var purchase = {
  //   items: [{ id: "xl-tshirt" }]
  // };

  var purchase = {};

  // Disable the button until we have Stripe set up on the page
  document.querySelector("button").disabled = true;
  fetch(window.location.pathname, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(purchase)
  })
    .then(function(result) {
      return result.json();
    })
    .then(function(data) {
      $('#receipt_intent_id').html(data['intent_id'])

      var elements = stripe.elements();

      var style = {
        base: {
          color: "#32325d",
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      var card = elements.create("card", { style: style });
      // Stripe injects an iframe into the DOM
      card.mount("#card-element");

      card.on("change", function (event) {
        // Disable the Pay button if there are no card details in the Element
        document.querySelector("button").disabled = event.empty;
        document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
      });

      var form = document.getElementById("payment-form");
      form.addEventListener("submit", function(event) {
        event.preventDefault();
        // Complete payment when the submit button is clicked
        payWithCard(stripe, card, data.clientSecret);
      });
    });

  // Calls stripe.confirmCardPayment
  // If the card requires authentication Stripe shows a pop-up modal to
  // prompt the user to enter authentication details without leaving your page.
  var payWithCard = function(stripe, card, clientSecret) {
    loading(true);
    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: card
        }
      })
      .then(function(result) {
        if (result.error) {
          // Show error to your customer
          showError(result.error.message);
        } else {
          // The payment succeeded!
          orderComplete(result.paymentIntent.id);
        }
      });
  };

  /* ------- UI helpers ------- */

  // Shows a success message when the payment is complete
  var orderComplete = function(paymentIntentId) {
    $.ajax({
      url: '/remote_jobs/payed',
      type: 'POST',
      dataType: 'json',
      data: { payment_intent_id: paymentIntentId },
    })
      .done(function(data) {
        // document
        //   .querySelector(".result-message a")
        //   .setAttribute(
        //     "href",
        //     "https://dashboard.stripe.com/test/payments/" + paymentIntentId
        //   );
        // document.querySelector(".result-message").classList.remove("hidden");
        // document.querySelector("button").disabled = true;

        window.location.href = '/remote_jobs/' + data.payment_intent_id + '/remind_extend_payed_complete'
      })
      .fail(function(data) {
        loading(false);

        document.querySelector(".failed-message").classList.remove("hidden");
        document.querySelector("button").disabled = true;
      });
  };

  // Show the customer the error from Stripe if their card fails to charge
  var showError = function(errorMsgText) {
    loading(false);
    var errorMsg = document.querySelector("#card-error");
    errorMsg.textContent = errorMsgText;
    setTimeout(function() {
      errorMsg.textContent = "";
    }, 4000);
  };

  // Show a spinner on payment submission
  var loading = function(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("button").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("button").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  };
}});