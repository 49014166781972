$(function(){ if (
    document.getElementById("remote_jobs_new") ||
    document.getElementById("remote_jobs_create") ||
    document.getElementById("preview_create") ||
    document.getElementById("remote_jobs_edit") ||
    document.getElementById("remote_jobs_update")
) {
    setAttributes();
    setPermissionEditor();

    if(document.getElementById("preview_footer")) {
        syncFooter();
    }

    $(document).on('change', '[name="remote_job_form[apply_type]"]', function(){
        toggleApplyType();
    })

    $(document).on('change', '#remote_job_form_main_tag', function(){
        previewMainTag();
        syncFooterMainTag()
    })

    $(document).on('input', '#remote_job_form_extra_tag', function(){
        previewExtraTag();
        syncFooterExtraMainTag()
    })

    $(document).on('input', '#remote_job_form_location_tag', function(){
        syncFooterLocationTag()
    })

    $(document).on('input', '#remote_job_form_title', function(){
        syncFooterJobTitle();
    })

    $(document).on('input', '#remote_job_form_company_name', function(){
        syncFooterCompanyName();
        syncFooterCompanyLogoWithName();
    })

    $(document).on('input', '#remote_job_form_company_logo', function(e){
        const reader = new FileReader();
        reader.onload = function (e) {
            const footerPreviewLogo = $("#footer_preview_logo");
            footerPreviewLogo.attr("src", e.target.result);
            footerPreviewLogo.attr("style", 'height:64px;width:64px;object-fit: cover;');
        }

        if(e.target.files.length > 0) {
            reader.readAsDataURL(e.target.files[0])
        }
    })

    $(document).on('input', '#remote_job_form_brand_color', function(e){
        syncFooterColors()
    })

    $(document).on('input', '#remote_job_form_minimum_year_salary, #remote_job_form_maximum_year_salary', function(){
        syncFooterSalary()
    })

    $(document).on('click', '#reset_default_color_link', function(){
        const remotefulBrandColor = '#fbf5d5';
        $('#remote_job_form_brand_color').val(remotefulBrandColor);

        syncFooterColors();
    })

    function syncFooter() {
        syncFooterJobTitle();
        syncFooterMainTag();
        syncFooterExtraMainTag();
        syncFooterLocationTag();
        syncFooterSalary();
        syncFooterCompanyName();
        syncFooterCompanyLogoWithName();
        syncFooterColors();

        // 画像表示
        const previewLogo = document.getElementById('preview_company_logo');
        if(previewLogo) {
            const src = previewLogo.getAttribute('src');
            const footerPreviewLogo = $("#footer_preview_logo");
            footerPreviewLogo.attr("src", src);
            footerPreviewLogo.attr("style", 'height:64px;width:64px;object-fit: cover;');
        }
    }

    function syncFooterJobTitle() {
        let inputTitleText = $('#remote_job_form_title').val().trim();

        if (inputTitleText.length <= 0) {
            inputTitleText = "Job Title"
        }
        document.getElementById("footer_preview_job_title").innerHTML = inputTitleText;
    }

    function syncFooterCompanyName() {
        let inputTitleText = $('#remote_job_form_company_name').val().trim();

        if (inputTitleText.length <= 0) {
            inputTitleText = "Company Name"
        }
        document.getElementById("footer_preview_company_name").innerHTML = inputTitleText;
    }

    function syncFooterColors() {
        const colorCode = $('#remote_job_form_brand_color').val();
        const boxContent = $("#box_content");
        const footerPreviewCompanyName = $("#footer_preview_company_name");
        const footerPreviewJobTitle = $("#footer_preview_job_title");

        boxContent.attr("style", 'background-color: ' + colorCode + ' !important;');
        footerPreviewCompanyName.attr("style", 'color: ' + colorCode + ' !important;');
        footerPreviewJobTitle.attr("style", 'color: ' + colorCode + ' !important;');
    }

    function syncFooterCompanyLogoWithName() {
        const footerPreviewLogo =  document.getElementById('footer_preview_logo');
        const src = footerPreviewLogo.getAttribute('src');
        if (!src.match(/https:\/\/via\.placeholder\.com/)) {
            return;
        }
        let companyName = $('#remote_job_form_company_name').val().trim();

        if (companyName.length <= 0) {
            companyName = "C"
        } else {
            companyName = companyName[0]
        }

        footerPreviewLogo.setAttribute("src", "https://via.placeholder.com/250x250/ffffff/d3d3d3?text=" + companyName);
    }

    function syncFooterMainTag() {
        let mainTagText = "Main Tag"

        const obj = document.getElementById('remote_job_form_main_tag');
        if (obj.options.selectedIndex <= 0) {
            mainTagText = "Main Tag";
        } else {
            mainTagText = obj.options[obj.options.selectedIndex].text
        }

        document.getElementById("footer_preview_main_tag").innerHTML　=　mainTagText;
    }

    function syncFooterExtraMainTag() {
        $('#footer_preview_extra_tags').empty();

        const inputTagText = $('#remote_job_form_extra_tag').val().trim();
        if (inputTagText.length === 0) {
            $('#footer_preview_extra_tags').append('<a class="tag is-extra-tag" style="margin-top: 0.1rem;" href="javascript:void(0);"><h3 id="footer_preview_main_tag" style="font-size: .75rem; margin-bottom: 0;">Extra Tag</h3></a>');
            return;
        }

        const splitInputTagTextArray = inputTagText.split(',').filter(Boolean).map(x => x.trim().toUpperCase());
        $.each(splitInputTagTextArray, function(index, val) {
            $('#footer_preview_extra_tags').append(`<a class="tag is-extra-tag" style="margin-top: 0.1rem;" href="javascript:void(0);"><h3 id="footer_preview_main_tag" style="font-size: .75rem; margin-bottom: 0;">${val}</h3></a>`);
        });
    }

    function syncFooterLocationTag() {
        let locationTagText = "Location";

        const obj = document.getElementById('remote_job_form_location_tag');
        if (obj.options.selectedIndex <= 0) {
            locationTagText = "Location";
        } else {
            locationTagText = obj.options[obj.options.selectedIndex].text
        }

        document.getElementById("footer_preview_location_tag").innerHTML　=　locationTagText;
    }

    function syncFooterSalary() {
        let minimumSalaryText = ""
        const inputMinimumSalary = document.getElementById('remote_job_form_minimum_year_salary');
        if (inputMinimumSalary.options.selectedIndex <= 0) {
            minimumSalaryText = "";
        } else {
            minimumSalaryText = inputMinimumSalary.options[inputMinimumSalary.options.selectedIndex].value
        }

        let maximumSalaryText = ""
        const inputMaximumSalary = document.getElementById('remote_job_form_maximum_year_salary');
        if (inputMaximumSalary.options.selectedIndex <= 0) {
            maximumSalaryText = "";
        } else {
            maximumSalaryText = inputMaximumSalary.options[inputMaximumSalary.options.selectedIndex].value
        }

        if(minimumSalaryText != '' && maximumSalaryText != '') {
            const minimumSalaryNumber = Number(minimumSalaryText)
            const maximumSalaryNumber = Number(maximumSalaryText)

            if (minimumSalaryNumber <= 0 && maximumSalaryNumber > 0) {
                document.getElementById("footer_preview_salary_tag").innerHTML　=　`💰 ~ $${maximumSalaryNumber / 1000}K`;
            } else if (minimumSalaryNumber < maximumSalaryNumber) {
                document.getElementById("footer_preview_salary_tag").innerHTML　=　`💰 $${minimumSalaryNumber / 1000}K ~ $${maximumSalaryNumber / 1000}K`;
            } else {
                document.getElementById("footer_preview_salary_tag").innerHTML　= "💰 Salary";
            }
        } else {
            document.getElementById("footer_preview_salary_tag").innerHTML　= "💰 Salary";
        }
    }

    function setPermissionEditor() {
        // ファイルの添付を無効にする
        window.addEventListener("trix-file-accept", function(event) {
            event.preventDefault();
        })

        // 画像添付をアイコンを非表示にする
        const attachIcon =  document.getElementsByClassName('trix-button--icon-attach')[0];
        if (attachIcon !== undefined) {
            attachIcon.parentNode.style = 'display: none;';
        }
    }

    function setAttributes() {
        previewMainTag();
        previewExtraTag();
        toggleApplyType();
    }

    function toggleApplyType() {
        const $applyEmailRadioButton = $('#remote_job_form_apply_email');
        const $applyURLRadioButton = $('#remote_job_form_apply_url');

        const applyType = $('[name="remote_job_form[apply_type]"]:checked').val()
        switch (applyType) {
            case 'url':
                $applyEmailRadioButton.prop("disabled", true);
                $applyURLRadioButton.prop("disabled", false);

                break;
            case 'email':
                $applyURLRadioButton.prop("disabled", true);
                $applyEmailRadioButton.prop("disabled", false);

                break;
        }
    }

    function previewMainTag() {
        $('#main_tag_preview').empty();

        const $selectedInput = $('#remote_job_form_main_tag').children(':selected');
        if ($selectedInput.val() === '') {
            return;
        }

        const selectedText = $selectedInput.text().toUpperCase();
        $('#main_tag_preview').empty().append(`<span class="tag is-extra-tag">${selectedText}</span>`);
    }

    function previewExtraTag() {
        $('#extra_tag_preview').empty();

        const inputTagText = $('#remote_job_form_extra_tag').val().trim();
        if (inputTagText.length === 0) {
            return;
        }

        const splitInputTagTextArray = inputTagText.split(',').filter(Boolean).map(x => x.trim().toUpperCase());
        $.each(splitInputTagTextArray, function(index, val) {
            $('#extra_tag_preview').append(`<span class="tag is-extra-tag">${val}</span>`);
        });
    }
}});