$(function(){
  $("a.preload-cloudflare").mouseover(function() {
    if($(this).data("preload")) {
      $(this).data('preload', false);

      const url = $(this).attr("href");
      $.get(url);
    }
  });
});
